<template>
  <div class="main-content">
    <avue-crud
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >
      <template #header>
        <div>
          <div class="flex align-items marginBottom20">
            <div class="flex align-items marginRight10">
              <span class="span marginRight10">消息搜索 : </span>
              <div class="width200">
                <el-input
                  v-model="searchData.content"
                  placeholder="请输入"
                  @keyup.enter.native="handleSearch"
                  clearable
                  maxlength="20"
                ></el-input>
              </div>
            </div>

            <div class="flex align-items marginRight10">
              <span class="span marginRight10">紧急程度 : </span>
              <div class="width200">
                <el-select
                  class="width250"
                  v-model="searchData.level"
                  clearable
                  @change="handleSearch"
                >
                  <el-option value="" label="请选择"></el-option>
                  <el-option :value="1" label="1级"></el-option>
                  <el-option :value="2" label="2级"></el-option>
                  <el-option :value="3" label="3级"></el-option>
                </el-select>
              </div>
            </div>

            <div class="flex align-items marginRight10">
              <span class="span marginRight10">消息接收时间 : </span>
              <div class="width300">
                <el-date-picker
                  v-model="searchData.createTime"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="请选择开始日期"
                  end-placeholder="请选择结束日期"
                  @change="handleSearch"
                  clearable
                >
                </el-date-picker>
              </div>
            </div>
            <el-button
              size="medium"
              type="primary"
              icon="el-icon-search"
              @click="handleSearch"
              class="marginLeft10"
              >查询</el-button
            >
            <el-button
              size="medium"
              type="info"
              icon="el-icon-refresh-right"
              @click="handleReset"
              class="marginLeft10"
              >重置</el-button
            >
          </div>
          <div class="flex justify-content-space-between">
            <div class="marginRight10 marginBottom10" style="text-align: right">
              <el-radio-group v-model="stateRadio" @input="getChage">
                <el-radio-button label="2">未读</el-radio-button>
                <el-radio-button label="1">已读</el-radio-button>
                <el-radio-button label="">全部</el-radio-button>
              </el-radio-group>
            </div>
            <div class="marginRight10 marginBottom10" style="text-align: right">
              <el-button
                type="primary"
                size="medium"
                @click="oneTouch"
                >一键已读</el-button
              >
            </div>
          </div>
        </div>
      </template>
      <template slot="menu" slot-scope="{ row }">
        <el-button
          v-if="row.isRead === 2"
          type="text"
          size="mini"
          @click="handleRead(row)"
          >已读</el-button
        >
        <!-- <el-button
          v-if="$route.query.type !== 3 && $route.query.type !== 4"
          type="text"
          size="mini"
          @click="handleDetails(row)"
          >详情</el-button
        > -->
      </template>
    </avue-crud>
  </div>
</template>
  
  <script>
import { detailList, manageRead, readAll } from "@/api/message/index";
import dayjs from "dayjs";
import qs from "qs";
export default {
  name: "index",
  data() {
    return {
      stateRadio: 2,
      searchData: {
        content: "",
        level: "",
        createTime: "",
      },
      page: {
        total: 0,
        currentPage: 1,
      },
      tableData: [],
      showLoading: false,
      option: {
        title: "销售合伙人消息列表",
        titleSize: "h3",
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: true, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: "消息",
            prop: "content",
          },
          {
            label: "接收时间",
            prop: "createTime",
          },
          {
            label: "紧急程度",
            prop: "level",
            formatter: function (row, column, cellValue) {
              return row.level === 1 ? "1级" : row.level === 2 ? "2级" : "3级";
            },
          },
          {
            label: "消息状态",
            prop: "isRead",
            formatter: function (row, column, cellValue) {
              return row.isRead === 2
                ? "未读"
                : row.isRead === 1
                ? "已读"
                : "全部";
            },
          },
        ],
      },

      dialogVisible: false,
      form: {
        id: "",
        level: "",
        content: "",
        createTime: "",
      },
    };
  },
  created() {
    const searchForm = JSON.parse(localStorage.getItem("searchForm"));
    if (searchForm?.path === this.$route.path) {
      this.searchData = searchForm.searchData;
      if (
        searchForm.searchData.createTimeStart &&
        searchForm.searchData.createTimeEnd
      ) {
        this.searchData.createTime = [
          searchForm.searchData.createTimeStart,
          searchForm.searchData.createTimeEnd,
        ];
      }
    } else {
      localStorage.removeItem("searchForm");
    }
  },
  methods: {
    onLoad() {
      this.showLoading = true;
      const searchData = {
        level: this.searchData.level,
        content: this.searchData.content,
      };
      if (this.searchData.createTime) {
        searchData.createTimeStart = dayjs(
          this.searchData.createTime[0]
        ).format("YYYY-MM-DD 00:00:00");
        searchData.createTimeEnd = dayjs(this.searchData.createTime[1]).format(
          "YYYY-MM-DD 23:59:59"
        );
      }
      const searchForm = {
        path: this.$route.path,
        searchData,
      };
      console.log("11111111111");
      localStorage.setItem("searchForm", JSON.stringify({ ...searchForm }));
      detailList({
        page: this.page.currentPage,
        size: this.page.pageSize,
        type: this.$route.query.type,
        isRead: this.stateRadio,
        ...searchData,
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },

    // 查询
    handleSearch() {
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset() {
      localStorage.removeItem("searchForm");
      // console.log('重置')
      this.searchData = {
        level: "",
        content: "",
        createTime: "",
      };
      this.page.currentPage = 1;
      this.onLoad();
    },

    // 一键已读
    oneTouch(r) {
      readAll({
        type: this.$route.query.type,
      }).then((res) => {
        if (res.code === 200) {
          this.onLoad();
          this.$store.dispatch("getStateRed",'');
        }
      });
    },
    //已读未读全部切换
    getChage(e) {
      this.stateRadio = e;
      this.onLoad();
      console.log(e, "切换状态");
    },
    //单个已读
    handleRead(r) {
      manageRead({
        id: r.id,
      }).then((res) => {
        if (res.code === 200) {
          this.$message.success("提交成功!");
          this.onLoad();
          this.$store.dispatch("getStateRed",'');
        }
      });
    },
    handleDetails(r) {
  
    },
  },
};
</script>
  
  <style scoped>
    /deep/.el-table .cell {
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    word-break: break-all;
    line-height: 21px!important;
    padding-right: 10px;
}
</style>